<template>
  <b-card class="mb-3 card-custom-class">
    <b-row v-if="$store.state['checkout'].couponLoading">
      <b-col>
        <b-skeleton class="mb-2" animation="wave" width="85%"></b-skeleton>
        <b-skeleton class="mb-2" animation="wave" width="55%"></b-skeleton>
        <b-skeleton animation="wave" width="70%"></b-skeleton>
      </b-col>
    </b-row>
    <b-row v-if="!$store.state['checkout'].couponLoading">
      <b-col>
        <b-card-text class="address-main"> Apply Coupon Code </b-card-text>
        <b-card-text class="address-details">
          <img class="mr-1" src="img/checkout/Coupon.png" alt="" /><input
            class="coupon-input"
            type="text"
            disabled="true"
            :value="selectedCoupon.title"
            placeholder="Enter Coupon  code"
          />
          <span
            @click="$bvModal.show('bv-modal-coupon')"
            class="float-right apply-coupon"
            >Apply
          </span>
        </b-card-text>
      </b-col>
      <b-modal
        id="bv-modal-coupon"
        size="lg"
        :hide-footer="true"
        :hide-header="true"
        :no-close-on-esc="true"
        :no-close-on-backdrop="true"
        centered
        header-class="my-class"
        body-class="p-0"
      >
        <b-card style="margin-top: 10px" class="card-custom-class">
          <div class="closemodel">
            <button @click="$bvModal.hide('bv-modal-coupon')" type="button">
              <span><img src="img/close.png" alt="" /></span>
            </button>
          </div>
          <span class="change-location-text">Apply Coupon Code </span>
        </b-card>
        <b-list-group class="list-group-modal">
          <b-card
            body-class="body-class-modal"
            v-for="(coupon, index) in couponList"
            :key="index"
            class="card-custom-class"
          >
            <b-row>
              <b-col md="6">
                <b-card-text>
                  <b-form-radio
                    @change="changeRadio"
                    :value="coupon"
                    name="radio-size"
                    size="lg"
                    ><span class="address-name">{{ coupon.title }}</span>
                  </b-form-radio>
                </b-card-text>
              </b-col>
              <b-col> </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-card-text class="address-details-text"
                  >{{ coupon.description }}
                </b-card-text>
              </b-col>
            </b-row>
          </b-card>
        </b-list-group>
      </b-modal>
    </b-row>
  </b-card>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    callApi: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState({
      selectedCoupon: (state) => state.checkout.selectedCoupon,
      couponList: (state) => state.checkout.couponList,
    }),
  },
  methods: {
    changeRadio(e) {
      this.$store.commit("checkout/updateselectedCoupon", e);
      this.$bvModal.hide("bv-modal-coupon");
    },
  },
  async mounted() {
    if (this.callApi) await this.$store.dispatch("checkout/fetchCouponList");
  },
};
</script>
<style scoped>
.card-custom-class {
  border: none;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-bottom-color: rgba(0, 0, 0, 0.125);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-radius: 0.25rem;
}
.address-details {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 11px;
  text-transform: capitalize;
  color: #9e9e9e;
}
/*  */
.list-group-modal {
  margin-top: 7px;
  max-height: 340px;
  margin-left: 10px;
  margin-right: 4px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.change-location-text {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #2e2e2e;
}
.add-new-address-text {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #ff6a2e;
  float: right;
  cursor: pointer;
}
.address-name {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #000000;
}
.body-class-modal {
  margin-top: -7px;
}
.address-details-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  color: #2e2e2e;
  margin-left: 37px;
  margin-top: 4px;
}
.my-class {
  background: black;
  color: white;
}
.closemodel {
  position: absolute;
  top: 0px;
  right: 3px;
}
.coupon-input {
  border: 0;
  border-bottom: 1px solid rgba(33, 33, 33, 0.08);
  outline: 0;
  width: 75%;
}
.apply-coupon {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-transform: capitalize;
  color: #ff6a2e;
  cursor: pointer;
}
[type="button"],
button {
  cursor: pointer;
  color: inherit;
  border: none;
  background-color: transparent;
}
.default-address-class {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #717171;
  margin-left: 10px;
}
.address-main {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 11px;
  text-transform: capitalize;
  color: #000000;
}
</style>
